import React, { useEffect } from 'react';
import './reminders.css';
import { API } from "./../../services/api/api";
import { Box, Card, CardContent, CardHeader, CircularProgress, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { isNullUndefinedEmpty, parseDate } from '../../utils/utils';
import { useParams, useNavigate } from 'react-router-dom';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const columns = [
  {
    field: 'name',
    headerName: 'Name',
    headerClassName: 'super-app-theme--header',
  //   width: 150,
    flex: 1,
    editable: false,
  },
  {
    field: 'cas',
    headerName: 'CAS',
    headerClassName: 'super-app-theme--header',
  //   width: 150,
    flex: 1,
    editable: false,
  },
  {
    field: 'last_updated',
    headerName: 'Last Updated Date',
    headerClassName: 'super-app-theme--header',
  //   width: 150,
    flex: 1,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    valueGetter: (params) => params.value,
    valueFormatter: (params) => {
      return parseDate(params.value);
    },
  },
];

export default function Reminders({onSelect}) {

    const navigate = useNavigate();

    const [rows, setRows] = React.useState([]);

    useEffect(() => {

        const fetchCompounds = async () => {
          let result = await API.getFilteredData(null);

          if (!isNullUndefinedEmpty(result)) {
            const currentDate = new Date();
    
            const filteredCompounds = result.filter(compound => {
              const lastUpdatedDate = new Date(compound.last_updated);
              const yearsDifference = currentDate.getFullYear() - lastUpdatedDate.getFullYear();
              const isMoreThanThreeYears = yearsDifference > 3 || 
                (yearsDifference === 3 && currentDate.getMonth() > lastUpdatedDate.getMonth()) || 
                (yearsDifference === 3 && currentDate.getMonth() === lastUpdatedDate.getMonth() && currentDate.getDate() > lastUpdatedDate.getDate());
              return isMoreThanThreeYears;
            });
    
            const compoundList = filteredCompounds.map(compound => ({
              _key: compound._key,
              name: compound.name,
              cas: compound.cas,
              // last_updated: new Date(compound.last_updated).toLocaleDateString(),
              last_updated: compound.last_updated,
            }));
    
            // for(const compound of compoundList) {
            //   toast.info(`${compound.name} (${compound.cas}) has not been updated in the last 3 years.`)
            // }

            compoundList.sort((a, b) => a.last_updated - b.last_updated);

            console.log(compoundList);

            setRows(compoundList);
          }
        }
    
        fetchCompounds();
      }, []);

      const handleRowClick = async (params) => {
        const substance = await API.getPropertyData("_key", params.row._key);
        onSelect(substance[0]);
        navigate("/ids/"+substance[0]._key);
      };

  return (
    <>
      {rows.length >= 1 ? (
          <Box sx={{'& .super-app-theme--header': {
              backgroundColor: 'rgba(207,222,208, 0.55)',
            },}}>
              <Card style={{color:"#f5c242"}}>
                  <CardHeader
                      avatar={
                          <WarningAmberIcon sx={{ width: '50px', height: '50px' }} />
                      }
                      title={<Typography style={{ textAlign: 'left' }} variant="h5">Reminders</Typography>}
                  />
                  <CardContent>
                      <DataGrid 
                          getRowId={(row) => row._key}
                          rows={rows}
                          onRowClick={handleRowClick}
                          autoHeight
                          columns={columns}
                          initialState={{
                              pagination: {
                                  paginationModel: {
                                      pageSize: 5,
                                  },
                              },
                          }}
                          pageSizeOptions={[5]}
                          disableRowSelectionOnClick
                      />
                  </CardContent>
              </Card>
          </Box>
      ) : (
          null
      )}
  </>
  );
}