import './notification.css';
import { API } from "./../../services/api/api";
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import CircularProgress from '@mui/material/CircularProgress';
import SpeedIcon from '@mui/icons-material/Speed';
import { Typography } from '@mui/material';
import { CognitoAuth } from '../../auth/cognito';
import { conversionTable, getLabel, hasOwnNestedProperty, hasOwnNestedPropertyList, isObjectEmpty, parseDate, parseObjectIntoString } from '../../utils/utils';
import { ContactSupportOutlined } from '@mui/icons-material';

const diff_keys = [
                   "cas", 
                   "name", 
                   "categories",
                   "containers",
                   "approval_status",
                   "chem_id.CRAMER_class",
                   "tox_data.inhalation_exposure.applicable_TTC",
                   "tox_data.oral_exposure.applicable_TTC",
                   "tox_data.inhalation_exposure.toxicity.uncertainty_factors.UFDi",
                   "tox_data.inhalation_exposure.toxicity.uncertainty_factors.UFDii",
                   "tox_data.oral_exposure.toxicity.uncertainty_factors.UFDi",
                   "tox_data.oral_exposure.toxicity.uncertainty_factors.UFDii",
                   "tox_data.inhalation_exposure.toxicity.POD.value",
                   "tox_data.oral_exposure.toxicity.POD.value", 
                   "label", // generic
                   "product_daily_max_item_use", // platform 
                   "product_daily_max_unit_use", // platform 
                   "mass_item", // platform 
                   "transfer_ratio", // platform 
                   "safety_factor", // platform 
                   "contact_surface", // platform 
                   "oral", // platform 
                   "inhalation", // platform 
                   "SAF", // platform 
                   "retention_rate", // platform 
                   "human_exposure_per_day", // population_concerned
                   "human_exposure_per_week", // population_concerned
                   "human_daily_inhalation_rate", // population_concerned
                   "human_body_weight", // population_concerned
                   "allometric_scaling", // animals 
                   "standard_respiratory_volume", // animals 
                   "inhalation_value", // UFA
                   "oral_value", // UFA
                   "general", // UFH
                   "workers", // UFH
                   "NOAEL", // UFL
                   "NOEL", // UFL
                   "LOAEL_low", // UFL
                   "LOAEL_mild", // UFL
                   "LOAEL_severe", // UFL
                   "NOAEC", // UFL
                   "NOEC", // UFL
                   "BMD", // UFL
                   "chronic", // UFS
                   "sub-chronic", // UFS
                   "sub-acute", // UFS
                   "ttc_inhalation", // CRAMER_class
                   "ttc_oral", // CRAMER_class
                   "value" // human_route_exposure && animal_route_administration
                  ]

const master_data_keys = 
                  ["label", // generic
                   "product_daily_max_item_use", // platform 
                   "product_daily_max_unit_use", // platform 
                   "mass_item", // platform 
                   "transfer_ratio", // platform 
                   "safety_factor", // platform 
                   "contact_surface", // platform 
                   "oral", // platform 
                   "inhalation", // platform 
                   "SAF", // platform 
                   "retention_rate", // platform 
                   "human_exposure_per_day", // population_concerned
                   "human_exposure_per_week", // population_concerned
                   "human_daily_inhalation_rate", // population_concerned
                   "human_body_weight", // population_concerned
                   "allometric_scaling", // animals 
                   "standard_respiratory_volume", // animals 
                   "inhalation_value", // UFA
                   "oral_value", // UFA
                   "general", // UFH
                   "workers", // UFH
                   "NOAEL", // UFL
                   "NOEL", // UFL
                   "LOAEL_low", // UFL
                   "LOAEL_mild", // UFL
                   "LOAEL_severe", // UFL
                   "NOAEC", // UFL
                   "NOEC", // UFL
                   "BMD", // UFL
                   "chronic", // UFS
                   "sub-chronic", // UFS
                   "sub-acute", // UFS
                   "ttc_inhalation", // CRAMER_class
                   "ttc_oral", // CRAMER_class
                   "value" // human_route_exposure && animal_route_administration
                  ];

const columns = [
    {
      field: 'element',
      headerName: 'Element',
      headerClassName: 'super-app-theme--header',
    //   width: 150,
      flex: 1,
      editable: false,
    },
    {
      field: 'name',
      headerName: 'Item',
      headerClassName: 'super-app-theme--header',
    //   width: 150,
      flex: 1,
      editable: false,
    },
    {
      field: 'approval_status',
      headerName: 'Approval Status',
      headerClassName: 'super-app-theme--header',
    //   width: 150,
      flex: 1,
      editable: false,
    },  
    {
      field: 'category',
      headerName: 'Category',
      headerClassName: 'super-app-theme--header',
    //   width: 150,
      flex: 1,
      editable: false,
    },  
    {  
      field: 'differences',
      headerName: 'Update Description',
      headerClassName: 'super-app-theme--header',
    //   width: 150,
      flex: 1,
      editable: false,
    },
    {
      field: 'date',
      headerName: 'Date',
      headerClassName: 'super-app-theme--header',
      description: 'Last modified date',
      flex: 1,
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        editable: false,
        filterable: true,
        valueGetter: (params) => params.value,
        valueFormatter: (params) => {
          return parseDate(params.value);
        },
    },
    {
        field: 'author',
        headerName: 'From',
        headerClassName: 'super-app-theme--header',
        flex: 1,
      //   width: 150,
        editable: false,
      },
      // {
      //   field: 'status',
      //   headerName: 'Status',
      //   headerClassName: 'super-app-theme--header',
      //   flex: 1,
      // //   width: 150,
      //   editable: false,
      // },
  ];

export default function Notification({user}) {

    const [rows, setRows] = React.useState([]);

    useEffect(() => {

        const fetchData = async () => {
            if(isObjectEmpty(user)){
              user = await CognitoAuth.getCurrentUser();
            } 
            const result = await API.getAuditedData(user.username);
            // console.log(result);
            const mapped = result ? result.filter(y => hasOwnNestedPropertyList(y.differences, diff_keys))
                                          .map(x => ({_key: x._key, 
                                            element: x?.element?.cas, 
                                            name: x?.element?.name ? x?.element?.name : x?.differences?.label, 
                                            approval_status: x?.element?.approval_status,
                                            category: x?.element?.categories, 
                                            differences: parseDifferences(x.differences, x), 
                                            date: x.date,
                                            author: x.author }))
                                          .sort((a, b) => b.date - a.date) : [];
            // console.log(mapped);
            setRows(mapped);
        };  
            
      fetchData();
    }, []);

    const parseDifferences = (differences, ref) => {
      // console.log(differences);
      let diff = "";

      if(hasOwnNestedProperty(differences, "categories")){
        diff += "New value in categories : " + Object.values(differences.categories) + "\n";
      }

      if(hasOwnNestedProperty(differences, "containers")){
        let new_comp = "";
        differences.containers.forEach(x => new_comp += parseObjectIntoString(x));
        // Object.keys(conversionTable).forEach(key => {
        //   const regex = new RegExp(`\\b${key}\\b`, 'g');
        //   updated_comp = new_comp.replace(regex, getLabel(key));
        // });
        diff += "New composition : " + new_comp + "\n";
      }
      
      if(hasOwnNestedProperty(differences, "tox_data.inhalation_exposure.toxicity.POD.value")){
        diff += "New value in inhalation exposure for POD : " + differences?.tox_data?.inhalation_exposure?.toxicity?.POD?.value + "\n";
      }

      if(hasOwnNestedProperty(differences, "tox_data.oral_exposure.toxicity.POD.value")){
        diff += "New value in oral exposure for POD : " + differences?.tox_data?.oral_exposure?.toxicity?.POD?.value + "\n";
      }

      if(hasOwnNestedProperty(differences, "chem_id.CRAMER_class")){
        diff += "New value for the Cramer Class : " + differences?.chem_id?.CRAMER_class + "\n";
      }

      if(hasOwnNestedProperty(differences, "tox_data.inhalation_exposure.applicable_TTC")){
        diff += "New value in inhalation exposure for applicable TTC : " + differences?.tox_data?.inhalation_exposure?.applicable_TTC + "\n";
      }

      if(hasOwnNestedProperty(differences, "tox_data.oral_exposure.applicable_TTC")){
        diff += "New value in oral exposure for applicable TTC : " + differences?.tox_data?.oral_exposure?.applicable_TTC + "\n";
      }

      if(hasOwnNestedProperty(differences, "tox_data.inhalation_exposure.toxicity.uncertainty_factors.UFDi")){
        diff += "New value in inhalation exposure for UFD(i) : " + differences?.tox_data?.inhalation_exposure?.toxicity?.uncertainty_factors?.UFDi + "\n";
      }

      if(hasOwnNestedProperty(differences, "tox_data.inhalation_exposure.toxicity.uncertainty_factors.UFDii")){
        diff += "New value in inhalation exposure for UFD(ii) : " + differences?.tox_data?.inhalation_exposure?.toxicity?.uncertainty_factors?.UFDii + "\n";
      }

      if(hasOwnNestedProperty(differences, "tox_data.oral_exposure.toxicity.uncertainty_factors.UFDi")){
        diff += "New value in oral exposure for UFD(i) : " + differences?.tox_data?.oral_exposure?.toxicity?.uncertainty_factors?.UFDi + "\n";
      }

      if(hasOwnNestedProperty(differences, "tox_data.oral_exposure.toxicity.uncertainty_factors.UFDii")){
        diff += "New value in oral exposure for UFD(ii) : " + differences?.tox_data?.oral_exposure?.toxicity?.uncertainty_factors?.UFDii + "\n";
      }

      if(hasOwnNestedPropertyList(differences, master_data_keys)){

        let new_values = "";
        for (const [key, value] of Object.entries(differences)) {
          //console.log(key, value);
          if(key !== "_key" && key !== "_id" && key !== "_rev" && key !== "0")
            new_values += `${key}: ${value}` + "\n";
        }

        //console.log(ref);
        diff += "New value for " + (ref.element.hasOwnProperty("label") ? ref?.element?.label : ref?.element?.name ? ref?.element?.name : ref?.differences?.name) + " : " + new_values + "\n";
      }

      return diff;
    }


    return (
        <>
            {rows ? (
                <Box sx={{'& .super-app-theme--header': {
                    backgroundColor: 'rgba(207,222,208, 0.55)',
                  },}}>
                    <Card>
                        <CardHeader
                            avatar={
                                <NotificationsNoneIcon sx={{ width: '50px', height: '50px' }} />
                            }
                            title={<Typography style={{ textAlign: 'left' }} variant="h5">Notifications</Typography>}
                        />
                        <CardContent>
                            <DataGrid 
                                getRowId={(row) => row._key}
                                rows={rows}
                                autoHeight
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 5,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5]}
                                disableRowSelectionOnClick
                            />
                        </CardContent>
                    </Card>
                </Box>
            ) : (
                <CircularProgress />
            )}
        </>
    );
}